


























































































































import {
	Component,
	Vue,
	Watch
} from "vue-property-decorator"
import MaterialSelect from '@/components/material-select/index.vue'
import {
	PageMode
} from '@/utils/type'
import {
	apiUserLevelAdd,
	apiUserLevelDetail,
	apiUserLevelEdit
} from "@/api/user/user"
import {
	UserLevelDetail_Req,
	UserLevelDetail_Res,
	UserLevelAdd_Req,
	UserLevelEdit_Req
} from "@/api/user/user.d.ts"
@Component({
	components: {
		MaterialSelect,
	},
})
export default class GradeEdit extends Vue {

	/** S Data **/
	mode: string = PageMode['ADD'] // 当前页面【add: 添加用户等级 | edit: 编辑用户等级】
	identity: number | null = null // 当前编辑用户的身份ID  valid: mode = 'edit'
	isLevel: number | null = null  // 用户等级
	
	disabled: any = false; //是否禁用 查看详情时禁用

	form = {
		name: '', // 等级名称
		rank: 0, // 级别
		image: '', // 图标
		background_image: '', // 背景图
		remark: '', // 备注
		level_discount: 0, // 设置等级折扣：0-无等级折扣；1-参与等级折扣
		discount: 10, // 等级折扣
		condition_type: 0, // 设置等级条件：0-满足以下任意条件；1-满足以下全部条件
		single_money: 0, // 单笔消费金额（空表示没选中）
		total_money: 0, // 累计消费金额（空表示没选中）
		total_num: 0, // 累计消费次数 （空表示没选中)
	}
	is_single_money = false // 单笔消费金额是否为空
	is_total_money = false // 累计消费金额是否为空
	is_total_num = false // 累计消费次数是否为空

	formRules = {
		name: [{
			required: true,
			message: '请输入等级名称',
			trigger: 'blur'
		}],
		rank: [{
			required: true,
			message: '请输入等级级别',
			trigger: 'blur'
		}, {
			type: 'number',
			// min: 2,
			required: true,
			message: '请输入大于1的整数',
			trigger: 'blur',
		}],
		level_discount: [{
			required: true,
			message: '请选择等级折扣',
			// validator: (rule: object, level_discount: number, callback: any) => {
			// 	let discount = this.$refs.formRef.discount;
			// 	if (level_discount === 0) {
			// 		callback()
			// 	} else {
			// 		if (!discount) {
			// 			callback(new Error('请输入折扣等级'))
			// 		}
			// 		if (discount < 0 || discount < 10) {
			// 			callback(new Error('请输入0到10之间的数字，可以保留小数点2位数字'))
			// 		}
			// 	}
			// },
			trigger: 'change'
		}],
		condition_type: [{
			required: true,
			message: '请选择等级条件',
			trigger: 'change'
		}]
	}
	$refs!: {
		formRef: any
	}
	/** E Data **/

	/** S Methods **/
	// 表单提交
	onSubmit() {
		// 验证表单格式是否正确
		this.$refs.formRef.validate((valid: boolean): any => {
			if (!valid) return;

			// 请求发送
			switch (this.mode) {
				case PageMode['ADD']:
					return this.handleUserLevelAdd()
				case PageMode['EDIT']:
					return this.handleUserLevelEdit()
			}
		})
	}

	// 新增用户等级
	handleUserLevelAdd() {
		const form = this.form as UserLevelAdd_Req
		apiUserLevelAdd(form).then(() => {
			// this.$message.success('添加成功!')
			setTimeout(() => this.$router.go(-1), 500)
		}).catch(() => {
			//this.$message.error('保存失败!')
		})
	}

	// 编辑用户等级
	handleUserLevelEdit() {
		const params = this.form
		const id: number = this.identity as number
		apiUserLevelEdit({...params,id} as UserLevelEdit_Req)
			.then(() => {
				// this.$message.success('修改成功!')
				setTimeout(() => this.$router.go(-1), 500)
				//this.initFormDataForUserLevelEdit()
			})
			.catch(() => {
				// this.$message.error('保存失败!')
			})
	}
	// 表单初始化数据 [编辑模式] mode => edit
	initFormDataForUserLevelEdit() {
		apiUserLevelDetail({
			id: this.identity as number
		}).then((res: UserLevelDetail_Res) => {
			Object.keys(res).map(key => {
				this.$set(this.form, key, res[key])
			})
		}).catch(() => {
			// this.$message.error('数据初始化失败，请刷新重载！')
		})
	}
	/** E Methods **/

	/** S Life Cycle **/
	created() {
		const query: any = this.$route.query

		if (query.mode) this.mode = query.mode
		this.disabled = this.$route.query.disabled!=null?true:false;

		// 编辑模式：初始化数据
		if (this.mode === PageMode['EDIT']) {
			this.identity = query.id * 1
			this.isLevel = query.level * 1
			this.initFormDataForUserLevelEdit()
		}
	}
	/** E Life Cycle **/
	// 监听等级条件是否有输入，输入即勾选
	@Watch('form.single_money')
	changeSingleMoney(val: any) {
		if (val == 0) {
			this.is_single_money = false
		} else {
			this.is_single_money = true
		}
	}
	@Watch('form.total_money')
	changeTotalMoney(val: any) {

		if (val == 0) {
			this.is_total_money = false
		} else {
			this.is_total_money = true
		}
	}
	@Watch('form.total_num')
	changeTotalNum(val: any) {
		if (val == 0) {
			this.is_total_num = false
		} else {
			this.is_total_num = true
		}
	}
}
